import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">


<path d="M11290 25440 c-217 -4 -449 -10 -515 -14 -309 -17 -620 -40 -840 -60
-88 -9 -274 -22 -414 -31 -354 -22 -439 -34 -555 -77 -41 -15 -96 -20 -291
-28 -300 -12 -615 -30 -634 -36 -8 -3 -40 -7 -70 -10 -55 -5 -128 -17 -206
-35 -22 -5 -110 -16 -195 -24 -245 -24 -294 -32 -367 -60 -37 -14 -76 -30 -85
-35 -9 -5 -155 -16 -325 -25 -310 -16 -509 -40 -582 -71 -39 -17 -47 -18 -166
-29 -134 -12 -234 -33 -304 -64 -36 -16 -75 -32 -86 -35 -11 -3 -141 -12 -290
-21 -219 -13 -290 -21 -375 -42 -58 -14 -107 -30 -111 -34 -3 -5 -74 -18 -158
-29 -141 -19 -190 -32 -275 -76 -20 -10 -110 -22 -270 -34 -272 -22 -315 -29
-383 -63 -30 -15 -92 -30 -163 -41 -147 -22 -221 -42 -291 -76 -21 -11 -132
-27 -318 -46 -138 -14 -163 -20 -229 -51 -68 -32 -87 -36 -206 -44 -182 -12
-249 -26 -336 -69 -41 -20 -94 -39 -117 -42 -55 -8 -121 -40 -180 -89 -26 -21
-53 -39 -59 -39 -6 0 -21 -9 -34 -20 -13 -11 -27 -20 -32 -20 -4 0 -8 -7 -8
-15 0 -9 -12 -21 -27 -27 -36 -16 -93 -73 -93 -93 0 -9 -15 -22 -33 -30 -38
-16 -62 -40 -76 -76 -5 -15 -23 -32 -38 -39 -20 -9 -35 -28 -48 -59 -10 -25
-40 -76 -67 -112 -27 -37 -57 -94 -68 -128 -11 -34 -29 -76 -41 -93 -12 -16
-24 -51 -27 -76 -3 -26 -9 -50 -15 -53 -5 -4 -13 -82 -18 -175 -10 -201 -18
-274 -28 -274 -15 0 -49 -86 -65 -160 -8 -41 -20 -120 -26 -175 -6 -55 -15
-109 -19 -120 -33 -78 -88 -318 -80 -351 1 -5 0 -25 -4 -44 -3 -19 -8 -60 -11
-91 -3 -32 -16 -74 -30 -100 -13 -24 -27 -57 -30 -74 -2 -16 -12 -70 -20 -120
-9 -49 -22 -153 -29 -230 -10 -101 -20 -153 -36 -188 -32 -69 -59 -204 -66
-332 -5 -86 -14 -132 -41 -212 -40 -122 -51 -197 -68 -458 -12 -194 -13 -202
-43 -256 -37 -66 -56 -173 -72 -401 -6 -81 -16 -159 -22 -175 -25 -63 -36
-123 -63 -348 -9 -66 -16 -165 -17 -220 -3 -131 -21 -250 -46 -300 -34 -67
-53 -181 -64 -375 -11 -205 -20 -299 -44 -440 -34 -199 -49 -359 -75 -769 -5
-90 -14 -169 -19 -175 -28 -36 -68 -315 -75 -536 -11 -306 -26 -659 -31 -700
-3 -25 -8 -83 -11 -130 -5 -84 -17 -223 -35 -395 -37 -364 -54 -1319 -44
-2490 4 -495 9 -927 10 -960 2 -33 6 -134 9 -225 7 -158 24 -379 45 -580 24
-221 42 -538 57 -1010 8 -258 43 -506 77 -548 6 -7 16 -124 23 -260 8 -136 18
-283 23 -327 6 -44 13 -119 17 -168 4 -48 11 -87 17 -87 5 0 7 -10 3 -22 -6
-22 -2 -45 25 -185 14 -70 15 -85 29 -330 11 -197 28 -297 68 -383 29 -64 34
-103 47 -365 9 -169 46 -418 73 -486 6 -14 18 -111 26 -215 9 -104 19 -198 22
-209 18 -69 49 -155 55 -155 16 0 28 -78 34 -220 10 -225 39 -408 75 -478 15
-29 24 -79 35 -193 15 -162 36 -280 59 -333 33 -74 45 -128 50 -231 9 -158 40
-317 76 -377 15 -25 24 -73 35 -179 19 -185 22 -200 51 -286 13 -39 24 -78 24
-87 0 -9 4 -16 9 -16 4 0 13 -50 19 -112 12 -115 49 -280 64 -286 4 -2 8 -9 8
-15 0 -6 7 -18 16 -28 17 -19 19 -39 34 -282 5 -92 14 -176 20 -186 5 -11 10
-29 10 -41 0 -22 27 -79 43 -91 4 -3 15 -32 23 -64 16 -58 34 -94 67 -130 11
-11 36 -54 57 -96 22 -42 52 -87 68 -100 15 -13 38 -40 51 -60 13 -19 37 -43
53 -52 15 -9 31 -24 35 -34 8 -24 55 -72 70 -73 7 0 30 -18 52 -40 21 -22 50
-44 63 -50 12 -6 35 -18 49 -28 96 -64 119 -77 169 -94 59 -21 92 -37 100 -48
20 -27 242 -69 371 -70 88 0 157 -14 248 -50 84 -32 138 -44 286 -61 55 -6
129 -19 165 -29 114 -32 211 -49 363 -65 156 -16 233 -31 256 -49 38 -30 150
-55 341 -75 58 -7 143 -20 190 -31 137 -31 288 -55 415 -65 118 -9 229 -28
250 -42 11 -7 29 -13 41 -13 12 0 24 -5 26 -11 5 -16 137 -37 343 -54 232 -19
233 -19 345 -44 52 -11 158 -28 235 -36 357 -41 355 -41 450 -73 113 -39 203
-49 620 -73 105 -5 226 -17 270 -25 211 -40 301 -53 438 -64 175 -13 275 -25
337 -40 183 -45 484 -70 1063 -90 222 -7 315 -14 352 -26 86 -26 311 -48 654
-64 145 -6 1067 -10 2430 -10 2516 0 2723 5 3041 67 112 22 241 31 600 43 528
18 660 30 865 80 44 10 190 28 325 40 238 20 362 37 445 60 37 10 128 18 610
50 93 7 177 16 187 21 9 5 28 9 42 9 24 0 36 4 111 35 32 14 226 40 365 50 47
4 150 20 230 36 80 17 222 37 315 44 315 27 435 47 507 85 35 19 151 40 275
50 135 11 248 28 348 53 33 9 123 23 200 32 236 28 303 42 370 74 70 34 176
54 375 71 68 6 129 15 134 20 6 6 24 10 40 10 17 0 44 7 59 15 16 8 99 24 185
36 87 12 173 23 192 26 19 3 63 18 98 34 34 16 67 29 72 29 6 0 10 5 10 11 0
7 10 9 26 5 31 -8 235 9 359 30 58 10 108 26 140 44 28 15 81 38 118 50 44 14
80 34 101 55 18 18 44 36 57 40 27 8 119 75 119 86 0 4 8 10 19 14 10 3 55 43
99 88 155 159 172 179 172 211 0 10 18 38 40 62 22 24 40 50 40 59 0 8 4 15
10 15 5 0 16 23 24 51 9 28 26 61 38 73 48 45 64 116 78 358 5 92 14 175 19
185 32 55 64 162 81 263 11 68 29 135 44 165 35 72 54 176 65 374 9 156 13
178 34 210 47 72 80 196 89 336 1 17 5 32 8 35 33 27 77 246 85 420 11 220 16
263 39 299 41 69 66 165 81 324 4 37 17 100 30 141 31 98 63 326 73 526 8 151
11 170 36 218 38 75 63 185 76 342 7 74 13 137 15 140 2 3 15 57 28 120 28
129 52 342 67 594 8 126 15 178 29 210 18 39 37 108 60 221 6 28 12 101 14
163 2 61 7 115 10 119 4 3 7 34 7 67 0 34 13 149 30 256 17 107 35 227 41 267
5 39 16 199 24 355 12 242 18 300 40 389 22 89 42 231 59 419 24 263 31 5686
8 6120 -18 335 -36 481 -78 611 -10 29 -20 143 -29 325 -8 153 -23 338 -34
409 -11 72 -27 177 -36 235 -9 58 -19 139 -22 180 -2 41 -7 82 -10 90 -3 8 -7
56 -10 105 -2 50 -8 119 -13 155 -13 79 -51 233 -61 240 -14 11 -21 58 -34
253 -17 240 -32 383 -45 417 -5 14 -12 51 -15 83 -4 32 -13 73 -20 90 -7 18
-18 97 -25 177 -7 80 -16 150 -20 155 -4 6 -10 30 -14 55 -4 25 -13 54 -20 65
-38 61 -49 113 -62 315 -13 203 -39 388 -67 465 -8 22 -20 74 -26 115 -37 230
-51 279 -92 326 -16 17 -20 48 -29 195 -16 275 -27 341 -75 464 -13 33 -29 98
-35 143 -13 94 -45 189 -72 212 -21 17 -32 90 -48 315 -10 152 -28 234 -59
275 -11 16 -26 67 -36 121 -19 103 -61 233 -82 254 -9 10 -16 68 -23 197 -14
260 -32 342 -79 368 -11 5 -27 34 -36 65 -10 30 -22 55 -26 55 -5 0 -9 7 -9
16 0 9 -18 36 -40 60 -22 24 -40 52 -40 63 0 31 -179 219 -278 293 -29 22 -55
45 -58 53 -3 7 -25 20 -50 30 -25 9 -55 31 -69 50 -25 31 -117 75 -160 75 -10
0 -54 17 -99 37 -44 20 -101 40 -126 44 -127 21 -193 28 -272 32 -58 3 -95 9
-112 20 -62 41 -188 69 -369 82 -101 8 -173 23 -287 62 -81 27 -235 56 -260
48 -16 -5 -131 35 -154 54 -24 19 -160 40 -336 51 -102 6 -198 12 -215 14 -30
4 -111 32 -130 47 -20 14 -133 39 -245 53 -63 8 -128 22 -145 30 -80 40 -230
62 -484 72 -190 7 -294 19 -324 38 -49 32 -147 57 -302 77 -88 11 -170 24
-183 29 -13 6 -34 10 -47 10 -13 0 -25 4 -27 9 -5 15 -216 39 -433 50 -390 20
-430 23 -435 30 -20 29 -273 92 -300 76 -5 -3 -11 -1 -14 4 -3 5 -59 12 -123
16 -65 4 -145 14 -178 22 -33 8 -91 19 -130 24 -245 32 -205 29 -832 59 -162
7 -227 14 -270 29 -136 45 -262 60 -773 91 -96 6 -227 17 -290 25 -63 7 -178
16 -254 20 -77 4 -146 8 -155 10 -9 1 -88 6 -176 10 -88 4 -187 9 -220 11
-298 21 -2409 29 -3295 14z m-1685 -120 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3
9 27 11 27 11 20 0z m7192 -116 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m4200 -640 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-17370 -140 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2296
-1197 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m12839 -3331
c186 -6 418 -23 555 -42 208 -28 390 -58 490 -80 66 -14 141 -30 167 -34 92
-17 406 -107 448 -129 9 -5 31 -12 50 -16 32 -7 225 -79 295 -110 17 -8 53
-23 80 -35 65 -28 271 -131 332 -166 214 -121 497 -316 613 -421 19 -17 48
-42 65 -55 45 -35 264 -255 321 -323 153 -181 318 -411 330 -461 3 -13 10 -24
14 -24 4 0 47 -80 95 -177 105 -213 95 -190 149 -340 90 -245 148 -485 197
-814 38 -258 38 -968 -1 -1199 -83 -501 -155 -744 -323 -1090 -69 -143 -197
-356 -280 -465 -184 -245 -448 -510 -665 -668 -45 -33 -81 -61 -80 -62 2 -1
46 -19 98 -40 247 -98 662 -422 935 -731 111 -124 305 -385 305 -409 0 -6 41
-66 51 -75 13 -11 189 -380 213 -445 13 -38 34 -95 45 -125 50 -137 105 -355
141 -555 51 -295 55 -348 55 -790 0 -368 -3 -445 -23 -625 -13 -113 -25 -213
-28 -223 -3 -10 -14 -73 -24 -140 -21 -134 -44 -251 -59 -300 -6 -18 -17 -65
-26 -105 -42 -186 -146 -494 -232 -682 -56 -123 -187 -377 -204 -395 -5 -5 -9
-17 -9 -26 0 -10 -4 -19 -9 -21 -5 -2 -41 -52 -81 -112 -178 -271 -419 -547
-654 -751 -38 -33 -77 -67 -87 -76 -31 -30 -282 -214 -366 -269 -46 -29 -83
-57 -83 -62 0 -4 -7 -8 -16 -8 -9 0 -28 -8 -42 -18 -25 -18 -73 -44 -217 -122
-79 -42 -113 -59 -220 -110 -99 -48 -113 -54 -230 -100 -44 -17 -89 -35 -100
-39 -11 -5 -58 -23 -105 -40 -47 -18 -94 -36 -105 -40 -36 -16 -479 -139 -523
-146 -23 -4 -40 -11 -36 -16 3 -5 -2 -9 -11 -9 -10 0 -13 5 -9 13 6 9 4 9 -7
1 -8 -6 -77 -23 -154 -38 -405 -78 -493 -90 -1020 -143 -156 -16 -469 -18
-3785 -23 -1988 -3 -3618 -3 -3623 1 -5 3 -11 12979 -7 14509 0 7 7145 3 7400
-4z m417 8 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m80 -10 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-7920 -30 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m17990 -66 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-9527 2 c18 -12 2 -12 -25 0 -13 6 -15 9 -5 9 8 0
22 -4 30 -9z m312 -63 c-14 -6 -26 -7 -32 -1 -5 5 4 9 23 10 30 1 30 1 9 -9z
m278 -87 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m205 -60 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m936
-490 c31 -26 15 -26 -18 0 -14 11 -21 20 -15 20 5 0 20 -9 33 -20z m239 -180
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m405
-380 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m85 -101 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10
-16z m71 -56 c13 -16 12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z
m258 -448 c0 -5 -4 -3 -9 5 -5 8 -9 22 -9 30 0 16 17 -16 18 -35z m41 -60 c0
-8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m80
-166 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z
m27 -21 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m76 -188 c3 -11
1 -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z m134 -622 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m40 -260 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m0 -1110 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-10 -60 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-150
-690 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-7 -28 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-33 -62 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-127 -318 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-30 -60 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-210 -336 c0 -3 -7
-12 -15 -20 -16 -17 -18 -14 -9 10 6 15 24 23 24 10z m-80 -124 c0 -11 -2 -20
-4 -20 -2 0 -6 9 -9 20 -3 11 -1 20 4 20 5 0 9 -9 9 -20z m-150 -170 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-260 -260 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m275 -538 c-3
-3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m615 -574 c0 -6 -7 -5
-15 2 -8 7 -15 17 -15 22 0 6 7 5 15 -2 8 -7 15 -17 15 -22z m58 -113 c-3 -3
-9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m262 -359 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m157 -418 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m92 -398 c0 -10 -3 -8 -9 5 -12 27
-12 43 0 25 5 -8 9 -22 9 -30z m68 -272 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-10 -80 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0
-40z m10 -1230 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m10 -80
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-20 -50 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-60 -350 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-13 -134 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0
14 7 11 14 -7z m-27 -76 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-52 -308 c-4 -12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2 -15
-2 -27z m-78 -182 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-104
-248 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m-141 -302 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-86 -156 c-18 -16
-18 -16 -6 6 6 13 14 21 18 18 3 -4 -2 -14 -12 -24z m-232 -347 c-4 -8 -11
-15 -16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m-704 -739 c0
-2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m7457 -68 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-7622 -37 c-3 -6 -11 -11 -16 -11 -5
0 -4 6 3 14 14 16 24 13 13 -3z m-245 -156 c-7 -8 -18 -15 -24 -15 -6 0 -2 7
8 15 25 19 32 19 16 0z m-305 -165 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19
12 23 12 15 0z m-838 -336 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-200 -80 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-250
-40 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-77 -18 c0 -2 -9
-6 -20 -9 -11 -3 -18 -1 -14 4 5 9 34 13 34 5z m-1123 -152 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-174 -21 c-13 -2 -33 -2 -45 0 -13 2 -3
4 22 4 25 0 35 -2 23 -4z m10544 -755 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-1220 -3024 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z"/>
<path d="M10200 15670 l0 -1190 1611 0 c1612 0 1701 2 1949 36 332 45 580 126
743 240 26 19 50 34 55 34 4 0 45 35 91 79 58 53 81 82 76 91 -5 8 -4 11 3 6
13 -8 81 75 108 134 4 8 15 26 24 40 20 30 80 184 84 214 1 11 7 32 13 46 21
51 37 210 37 370 -1 134 -5 181 -22 252 -21 86 -105 278 -122 278 -5 0 -12 9
-15 21 -11 34 -155 172 -239 229 -83 56 -274 150 -304 150 -10 0 -22 4 -27 9
-13 11 -157 49 -325 84 -93 20 -315 44 -524 57 -95 6 -776 10 -1688 10 l-1528
0 0 -1190z m3123 1163 c-24 -2 -62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z
m-46 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m356 -1 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m62 -11 c-3 -3 -11 0 -18 7
-9 10 -8 11 6 5 10 -3 15 -9 12 -12z m252 -58 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m510 -170 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m213 -144 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m110 -73 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10
2 5 -8 10 -19 10 -23z m55 -107 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m70 -90 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m62 -232 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m10 -150 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z
m-536 -1041 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z"/>
<path d="M10207 11484 c-4 -4 -7 -686 -7 -1516 l0 -1508 1693 0 c1572 0 1966
6 2001 28 6 3 29 8 51 9 80 5 293 55 425 100 147 49 263 103 352 164 32 22 60
39 62 39 3 0 16 10 29 23 204 188 275 285 378 522 17 40 62 192 74 250 45 223
58 568 31 760 -35 242 -146 489 -284 635 -27 29 -34 33 -43 20 -8 -12 -9 -10
-4 7 5 18 -7 32 -72 84 -108 86 -150 112 -168 106 -8 -4 -14 -1 -12 4 9 41
-453 201 -608 212 -22 1 -44 5 -50 8 -15 9 -70 18 -155 25 -41 3 -115 10 -165
16 -104 12 -3516 24 -3528 12z m3216 -21 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22
4 25 0 35 -2 23 -4z m174 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m184 -22 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m399 -46
c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m357 -112 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m78 -34 c3 -5 2 -10 -4 -10 -5 0
-13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m52 -41 c-9 -5 -25 -5 -39 1
-22 10 -22 10 5 5 16 -3 27 -1 27 5 0 6 5 8 11 4 8 -5 7 -9 -4 -15z m68 -69
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m175
-80 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m234 -295 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10
-15z m153 -667 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-67
-508 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-150 -430 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-545 -480 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z
m-437 -137 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
